:global(portainer-tooltip) {
  @apply inline-flex;
}

.tooltip {
  background-color: var(--bg-tooltip-color) !important;
  color: var(--text-tooltip-color) !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  min-width: 50px;
  font-size: 1rem !important;
  text-align: left;
  font-weight: 400;
  width: max-content;
}

.tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.tooltip-container {
  line-height: 18px;
  padding: 8px 10px !important;
  font-size: 12px !important;
}

.tooltip-message a {
  color: var(--blue-15) !important;
}

.tooltip-heading {
  font-weight: 500;
}

.tooltip-beteaser {
  @apply text-blue-8 hover:text-blue-9;
}
