.portainer-selector-root {
  --multi-value-tag-bg: var(--grey-51);
  --single-value-option-text-color: currentColor;
}

:root[theme='dark'] .portainer-selector-root {
  --multi-value-tag-bg: var(--grey-3);
  --single-value-option-text-color: var(--white-color);
}

:root[theme='highcontrast'] .portainer-selector-root {
  --multi-value-tag-bg: var(--grey-3);
  --single-value-option-text-color: var(--white-color);
}

.portainer-selector__placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.portainer-selector-root.sm .portainer-selector__placeholder {
  @apply text-xs;
}

.portainer-selector-root.md .portainer-selector__placeholder {
  @apply text-sm;
}

.portainer-selector__indicator-separator {
  display: none;
}

.portainer-selector-root .portainer-selector__group-heading {
  text-transform: none !important;
  font-size: 85% !important;
}

.input-group .portainer-selector-root:last-child .portainer-selector__control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-group .portainer-selector-root:not(:first-child):not(:last-child) .portainer-selector__control {
  border-radius: 0;
}

/* input style */
.portainer-selector-root .portainer-selector__control {
  border-color: var(--border-form-control-color);
  background-color: var(--bg-inputbox);
  box-shadow: none;
}

/* Match the same box shadow styles as the other portainer inputs, which are inherited from the bootstrap form-control class */
.portainer-selector-root .portainer-selector__control--is-focused {
  border-color: #66afe9;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.portainer-selector-root .portainer-selector__control--is-focused:hover {
  border-color: #66afe9;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.portainer-selector-root.sm .portainer-selector__control {
  min-height: 30px;
}

.portainer-selector-root.md .portainer-selector__control {
  min-height: 34px;
}

.portainer-selector-root .portainer-selector__multi-value {
  background-color: var(--multi-value-tag-bg);
}

.portainer-selector-root .portainer-selector__input-container {
  color: var(--text-form-control-color);
}

.portainer-selector-root.sm .portainer-selector__input-container {
  height: 20px;
  @apply text-xs;
}

.portainer-selector-root .portainer-selector__dropdown-indicator {
  padding: 0 8px;
}

.portainer-selector-root .portainer-selector__clear-indicator {
  padding: 6px;
}

.portainer-selector-root .portainer-selector__multi-value__label {
  @apply text-black;
  @apply th-dark:text-white;
  @apply th-highcontrast:text-white;
}

.portainer-selector-root .portainer-selector__single-value {
  color: var(--single-value-option-text-color);
}

.portainer-selector-root.sm .portainer-selector__single-value {
  @apply text-xs;
}

.portainer-selector-root.md .portainer-selector__single-value {
  @apply text-sm;
}

/* Menu colors */
.portainer-selector__menu {
  --bg-multiselect-color: var(--white-color);
  --border-multiselect: var(--grey-48);

  --focused-option-bg: var(--ui-gray-3);
  --focused-option-color: currentColor;

  --selected-option-text-color: var(--grey-7);
}

:root[theme='dark'] .portainer-selector__menu {
  --bg-multiselect-color: var(--grey-1);
  --border-multiselect: var(--grey-3);

  --focused-option-bg: var(--blue-2);
  --focused-option-color: var(--white-color);

  --selected-option-text-color: var(--white);
}
:root[theme='highcontrast'] .portainer-selector__menu {
  --bg-multiselect-color: var(--black-color);
  --border-multiselect: var(--grey-3);

  --focused-option-bg: var(--blue-2);
  --focused-option-color: var(--white-color);

  --selected-option-text-color: var(--white);
}

.portainer-selector__menu-portal .portainer-selector__menu,
.portainer-selector-root .portainer-selector__menu {
  background-color: var(--bg-multiselect-color);
  border: 1px solid var(--border-multiselect);
  padding: 5px;
  z-index: 10;
}

.portainer-selector__menu-portal .portainer-selector__menu .portainer-selector__option,
.portainer-selector-root .portainer-selector__menu .portainer-selector__option {
  background-color: var(--bg-multiselect-color);
  border-radius: 5px;
}

.portainer-selector-root.sm .portainer-selector__menu .portainer-selector__option {
  @apply text-xs;
}

.portainer-selector__menu-portal .portainer-selector__menu .portainer-selector__option:active,
.portainer-selector__menu-portal .portainer-selector__menu .portainer-selector__option--is-focused,
.portainer-selector-root .portainer-selector__menu .portainer-selector__option:active,
.portainer-selector-root .portainer-selector__menu .portainer-selector__option--is-focused {
  background-color: var(--focused-option-bg);
  color: var(--focused-option-color);
}

.portainer-selector__menu-portal .portainer-selector__menu .portainer-selector__option--is-selected,
.portainer-selector-root .portainer-selector__menu .portainer-selector__option--is-selected {
  color: var(--selected-option-text-color);
}
